.payment {
    margin: auto;
    width: 600px;
    padding: 3rem 3.5rem;
    background: white;
    color: black;
}

@media(max-width:767px) {
    .payment {
        width: 100%;
        padding: 1.5rem;
    }
}

@media(height:1366px) {
    .payment {
        width: 100%;
        padding: 8vh
    }
}

.payment-dialog .MuiPaper-root{
    border-radius: 5px!important;
    background-color: white!important;
    width: 100%;
}

#payment-header {
    font-weight: bold;
    font-size: 0.9rem
}

.Btn{
    position: absolute;
    bottom: 10px;
    right: 10px;
}
