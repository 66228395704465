@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
.loading {
  display: grid;
  place-content: center;
  min-height: 100vh;
  margin: 0;
  padding: 2px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100vw;
}

.loading h1 {
  font-family: "Open Sans", -apple-system, "Segoe UI", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: #212121;
  box-sizing: border-box;
}

.dots {
  display: inline-flex;
}
.dots--animate .dot.z {
  -webkit-animation: scale 0.8s 0.2s forwards;
          animation: scale 0.8s 0.2s forwards;
}
.dots--animate .dot.f, .dots--animate .dot.s {
  -webkit-animation: right 0.5s forwards;
          animation: right 0.5s forwards;
}
.dots--animate .dot.l {
  -webkit-animation: rightDown 0.4s 0.1s forwards linear, drop 2s 0.4s forwards linear;
          animation: rightDown 0.4s 0.1s forwards linear, drop 2s 0.4s forwards linear;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #212121;
  border-radius: 10px;
  position: relative;
  margin-left: 6px;
}
.dot.z {
  position: absolute;
  transform: scale(0);
}
@-webkit-keyframes scale {
  100% {
    transform: scale(1);
  }
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}
.dot.f, .dot.s {
  transform: translateX(0px);
}
@-webkit-keyframes right {
  100% {
    transform: translateX(16px);
  }
}
@keyframes right {
  100% {
    transform: translateX(16px);
  }
}
.dot.t {
  background: transparent;
}
.dot .l {
  margin-left: 0;
  position: absolute;
  top: 0;
  left: 0;
}
@-webkit-keyframes rightDown {
  50% {
    top: 4px;
    left: 16px;
  }
  100% {
    top: 12px;
    left: 24px;
  }
}
@keyframes rightDown {
  50% {
    top: 4px;
    left: 16px;
  }
  100% {
    top: 12px;
    left: 24px;
  }
}
@-webkit-keyframes drop {
  100% {
    transform: translate(70px, calc(35px + (100vh/2)));
  }
}
@keyframes drop {
  100% {
    transform: translate(70px, calc(35px + (100vh/2)));
  }
}

.abs-twitter {
  position: fixed;
  right: calc(24px + .6vw);
  top: calc(22px + .6vw);
  transform: translate(-8px, 4px);
  opacity: 0;
  transition: 0.3s ease-in;
}
.abs-twitter--show {
  transform: translate(0, 0);
  opacity: 1;
  -webkit-animation: birdie 1s 0.8s linear infinite;
          animation: birdie 1s 0.8s linear infinite;
}
@-webkit-keyframes birdie {
  13% {
    transform: rotate(14deg);
  }
  26% {
    transform: rotate(28deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes birdie {
  13% {
    transform: rotate(14deg);
  }
  26% {
    transform: rotate(28deg);
  }
  100% {
    transform: rotate(0deg);
  }
}